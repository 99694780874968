<template>
  <div>
    <div class="box" v-loading="tabLoading">
      <el-tabs v-model="form.list_type" @tab-click="handleClickTabs">
        <template v-for="item in tabs">
          <el-tab-pane :key="item.field_value" :label="item.field_text" :name="item.field_value"></el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div class="card" style="display: flex">
      <div class="left">
        <el-button type="primary" icon="el-icon-upload2" plain @click="handleExport" :loading="exportLoading">导出</el-button>
      </div>
      <div class="line"></div>
      <div class="middle">
        <el-form :model="form" :inline="true">
          <el-form-item label="日期">
            <el-date-picker
              v-model="form.create_time"
              type="daterange"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="门店">
            <el-select
              v-model="form.hierarchy_id"
              placeholder="请选择"
              filterable=""
              :disabled="userInfo.hierarchy_type_id === 40"
            >
              <el-option
                v-for="item in merchantList"
                :key="item.hierarchy_id"
                :label="item.hierarchy_name"
                :value="item.hierarchy_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="货号">
            <el-input v-model="form.goods_number" placeholder="请输入" clearable=""></el-input>
          </el-form-item>
          <el-form-item label="款号">
            <el-input v-model="form.goods_style_number" placeholder="请输入" clearable=""></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="right" style="display: flex">
        <div class="line"></div>
        <el-button type="primary" @click="handleSearch">搜索</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button type="primary" @click="handleConf">列表配置</el-button>
      </div>
    </div>
    <div class="table">
      <el-table :data="tableData" style="width: 100%" :row-class-name="handleIndex" v-loading="loading" height="700px" ref="dataTable">
        <template v-for="item in columns">
          <el-table-column
            :key="item.field_alias"
            :prop="item.field_alias"
            :label="item.field_text"
            :width="item.t_widht"
            v-if="item.show"
            align="center"
            :fixed="item.fixed"
          >
            <template v-if="item.children">
              <el-table-column
                v-for="child in item.children"
                :key="child.field_alias"
                :prop="child.field_alias"
                :label="child.field_text"
                :width="child.t_widht"
                v-if="child.show"
                align="center"
                :fixed="item.fixed"
              >
                <template slot-scope="scope">
                  <span v-if="item.field_text !== '期间支出'">
                    {{ scope.row[child.field_alias] }}
                  </span>
                  <span v-else>
                    {{ scope.row[child.field_alias] ? '-' + scope.row[child.field_alias] : '' }}
                  </span>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Conf
      :dialogVisible="dialogVisible"
      :title="title"
      @onColumns="handleColumns"
      @onClose="handleClose"
      @onSuccess="handleSuccess"
      :listConf="listConf"
    />
  </div>
</template>

<script>
import Cond from "@/components/cond/Index.vue";
import Conf from "../components/Conf.vue";
import storage from "good-storage";
import { getMerchantListReq } from "@/api/authority/menage.js";
import numeral from 'numeral';
import { tableConfReq, getGoodsDataReq, exportReq, getColumnForMenuReq } from '@/api/statistics/goods.js';

export default {
  data() {
    const userInfo = storage.get("userInfo");
    return {
      hierarchy_id: '',
      userInfo,
      tabLoading: false,
      tabs: [],
      create_time: [],
      exportLoading: false,
      loading: false,
      listConf: [], // 列表配置项
      columns: [
        {
          field_text: "序号",
          field_alias: "index",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        },
        {
          field_text: '品类',
          field_alias: "list_name",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        },
        {
          field_text: "期初",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "start_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "净金重",
              field_alias: "start_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "总重",
              field_alias: "start_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "成本价",
              field_alias: "start_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "标签价",
              field_alias: "start_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "期末",
          show: true,
          children: [
            {
              field_text: "数量",
              field_alias: "end_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "净金重",
              field_alias: "end_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "总重",
              field_alias: "end_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "成本价",
              field_alias: "end_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "标签价",
              field_alias: "end_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期入库",
          show: true,
          children: [
            {
              field_text: "入库数量",
              field_alias: "entry_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "入库净金重",
              field_alias: "entry_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "入库总重",
              field_alias: "entry_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "入库成本价",
              field_alias: "entry_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "入库标签价",
              field_alias: "entry_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期退库",
          show: true,
          children: [
            {
              field_text: "退库数量",
              field_alias: "cancel_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "退库净金重",
              field_alias: "cancel_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "退库总重",
              field_alias: "cancel_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "退库成本价",
              field_alias: "cancel_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "退库标签价",
              field_alias: "cancel_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期调入",
          show: true,
          children: [
            {
              field_text: "调入数量",
              field_alias: "allot_to_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "调入净金重",
              field_alias: "allot_to_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "调入总重",
              field_alias: "allot_to_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "调入成本价",
              field_alias: "allot_to_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "调入标签价",
              field_alias: "allot_to_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期调出",
          show: true,
          children: [
            {
              field_text: "调出数量",
              field_alias: "allot_at_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "调出净金重",
              field_alias: "allot_at_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "调出总重",
              field_alias: "allot_at_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "调出成本价",
              field_alias: "allot_at_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "调出标签价",
              field_alias: "allot_at_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期修改收入",
          show: true,
          children: [
            {
              field_text: "修改数量",
              field_alias: "edit_at_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "修改净金重",
              field_alias: "edit_at_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "修改总重",
              field_alias: "edit_at_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "修改成本价",
              field_alias: "edit_at_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "修改标签价",
              field_alias: "edit_at_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期修改支出",
          show: true,
          children: [
            {
              field_text: "修改数量",
              field_alias: "edit_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "修改净金重",
              field_alias: "edit_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "修改总重",
              field_alias: "edit_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "修改成本价",
              field_alias: "edit_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "修改标签价",
              field_alias: "edit_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期拆改",
          show: true,
          children: [
            {
              field_text: "拆改数量",
              field_alias: "split_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "拆改净金重",
              field_alias: "split_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "拆改总重",
              field_alias: "split_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "拆改成本价",
              field_alias: "split_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "拆改标签价",
              field_alias: "split_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期销售",
          show: true,
          children: [
            {
              field_text: "销售数量",
              field_alias: "order_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "销售净金重",
              field_alias: "order_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "销售总重",
              field_alias: "order_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "销售成本价",
              field_alias: "order_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "销售标签价",
              field_alias: "order_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期退货",
          show: true,
          children: [
            {
              field_text: "退货数量",
              field_alias: "refund_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "退货净金重",
              field_alias: "refund_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "退货总重",
              field_alias: "refund_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "退货成本价",
              field_alias: "refund_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "退货标签价",
              field_alias: "refund_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期调仓收入",
          show: true,
          children: [
            {
              field_text: "调仓数量",
              field_alias: "allot_warehouse_to_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "调仓净金重",
              field_alias: "allot_warehouse_to_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "调仓总重",
              field_alias: "allot_warehouse_to_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "调仓成本价",
              field_alias: "allot_warehouse_to_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "调仓标签价",
              field_alias: "allot_warehouse_to_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        {
          field_text: "本期调仓支出",
          show: true,
          children: [
            {
              field_text: "调仓数量",
              field_alias: "allot_warehouse_at_data_total_num",
              t_widht: "120px",
              show: true,
              type: "total_num",
            },
            {
              field_text: "调仓净金重",
              field_alias: "allot_warehouse_at_data_total_gold_weight",
              t_widht: "120px",
              show: true,
              type: "total_gold_weight",
            },
            {
              field_text: "调仓总重",
              field_alias: "allot_warehouse_at_data_total_weight",
              t_widht: "120px",
              show: true,
              type: "total_weight",
            },
            {
              field_text: "调仓成本价",
              field_alias: "allot_warehouse_at_data_total_cost_price",
              t_widht: "120px",
              show: true,
              type: "total_cost_price",
            },
            {
              field_text: "调仓标签价",
              field_alias: "allot_warehouse_at_data_total_sale_price",
              t_widht: "120px",
              show: true,
              type: "total_sale_price",
            },
          ],
        },
        // {
        //   field_text: "期间收入",
        //   show: true,
        //   children: [
        //     // {
        //     //   field_text: "调仓数量",
        //     //   field_alias: "allot_warehouse_to_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "调仓净金重",
        //     //   field_alias: "allot_warehouse_to_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "调仓总重",
        //     //   field_alias: "allot_warehouse_to_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "调仓成本价",
        //     //   field_alias: "allot_warehouse_to_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "调仓标签价",
        //     //   field_alias: "allot_warehouse_to_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "修改数量",
        //     //   field_alias: "'edit_at_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "修改净金重",
        //     //   field_alias: "'edit_at_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "修改总重",
        //     //   field_alias: "'edit_at_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "修改成本价",
        //     //   field_alias: "'edit_at_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "修改标签价",
        //     //   field_alias: "'edit_at_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "入库数量",
        //     //   field_alias: "entry_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "入库净金重",
        //     //   field_alias: "entry_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "入库总重",
        //     //   field_alias: "entry_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "入库成本价",
        //     //   field_alias: "entry_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "入库标签价",
        //     //   field_alias: "entry_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "调入数量",
        //     //   field_alias: "allot_to_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "调入净金重",
        //     //   field_alias: "allot_to_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "调入总重",
        //     //   field_alias: "allot_to_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "调入成本价",
        //     //   field_alias: "allot_to_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "调入标签价",
        //     //   field_alias: "allot_to_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "退货数量",
        //     //   field_alias: "refund_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "退货净金重",
        //     //   field_alias: "refund_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "退货总重",
        //     //   field_alias: "refund_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "退货成本价",
        //     //   field_alias: "refund_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "退货标签价",
        //     //   field_alias: "refund_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //   ],
        // },
        // {
        //   field_text: "期间支出",
        //   show: true,
        //   children: [
        //     {
        //       field_text: "调仓数量",
        //       field_alias: "allot_warehouse_at_total_num",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_num",
        //     },
        //     {
        //       field_text: "调仓净金重",
        //       field_alias: "allot_warehouse_at_total_gold_weight",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_gold_weight",
        //     },
        //     {
        //       field_text: "调仓总重",
        //       field_alias: "allot_warehouse_at_total_weight",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_weight",
        //     },
        //     {
        //       field_text: "调仓成本价",
        //       field_alias: "allot_warehouse_at_total_cost_price",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_cost_price",
        //     },
        //     {
        //       field_text: "调仓标签价",
        //       field_alias: "allot_warehouse_at_total_sale_price",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_sale_price",
        //     },
        //     // {
        //     //   field_text: "销售数量",
        //     //   field_alias: "order_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "销售净金重",
        //     //   field_alias: "order_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "销售总重",
        //     //   field_alias: "order_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "销售成本价",
        //     //   field_alias: "order_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "销售标签价",
        //     //   field_alias: "order_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "调出数量",
        //     //   field_alias: "allot_at_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "调出净金重",
        //     //   field_alias: "allot_at_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "调出总重",
        //     //   field_alias: "allot_at_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "调出成本价",
        //     //   field_alias: "allot_at_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "调出标签价",
        //     //   field_alias: "allot_at_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "修改数量",
        //     //   field_alias: "edit_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "修改净金重",
        //     //   field_alias: "edit_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "修改总重",
        //     //   field_alias: "edit_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "修改成本价",
        //     //   field_alias: "edit_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "修改标签价",
        //     //   field_alias: "edit_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     // {
        //     //   field_text: "退库数量",
        //     //   field_alias: "cancel_data_total_num",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_num",
        //     // },
        //     // {
        //     //   field_text: "退库净金重",
        //     //   field_alias: "cancel_data_total_gold_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_gold_weight",
        //     // },
        //     // {
        //     //   field_text: "退库总重",
        //     //   field_alias: "cancel_data_total_weight",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_weight",
        //     // },
        //     // {
        //     //   field_text: "退库成本价",
        //     //   field_alias: "cancel_data_total_cost_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_cost_price",
        //     // },
        //     // {
        //     //   field_text: "退库标签价",
        //     //   field_alias: "cancel_data_total_sale_price",
        //     //   t_widht: "120px",
        //     //   show: true,
        //     //   type: "total_sale_price",
        //     // },
        //     {
        //       field_text: "拆改数量",
        //       field_alias: "split_data_total_num",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_num",
        //     },
        //     {
        //       field_text: "拆改净金重",
        //       field_alias: "split_data_total_gold_weight",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_gold_weight",
        //     },
        //     {
        //       field_text: "拆改总重",
        //       field_alias: "split_data_total_weight",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_weight",
        //     },
        //     {
        //       field_text: "拆改成本价",
        //       field_alias: "split_data_total_cost_price",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_cost_price",
        //     },
        //     {
        //       field_text: "拆改标签价",
        //       field_alias: "split_data_total_sale_price",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_sale_price",
        //     },
        //     {
        //       field_text: "成品转旧料数量",
        //       field_alias: "new_old_data_total_num",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_num",
        //     },
        //     {
        //       field_text: "成品转旧料净金重",
        //       field_alias: "new_old_data_total_gold_weight",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_gold_weight",
        //     },
        //     {
        //       field_text: "成品转旧料总重",
        //       field_alias: "new_old_data_total_weight",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_weight",
        //     },
        //     {
        //       field_text: "成品转旧料成本价",
        //       field_alias: "new_old_data_total_cost_price",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_cost_price",
        //     },
        //     {
        //       field_text: "成品转旧料标签价",
        //       field_alias: "new_old_data_total_sale_price",
        //       t_widht: "120px",
        //       show: true,
        //       type: "total_sale_price",
        //     },
        //   ],
        // },
      ], // 表格列数据
      tableData: [], // 表单数据
      merchantList: [], // 仓库列表
      form: {
        create_time: [],
        list_type: '10',
        hierarchy_id: '',
      }, // 表单数据
      title: "",
      dialogVisible: false,
      page: 1,
      mode: 1,
      limit: this.$store.state.pageSizes[0],
      rowList: [],
      printRows: [],
    };
  },
  components: {
    Cond,
    Conf,
  },
  created() {
    this.getColumnForMenu();
    // 获取上一个月
    this.getMonth();
    // 获取仓库列表
    this.getMerchantList();
    // 获取列表配置
    this.tableConf();
  },
  methods: {
    // 获取tab
    getColumnForMenu() {
      this.tabLoading = true;
      getColumnForMenuReq()
        .then((res) => {
          if (res.code === 1) {
            this.tabLoading = false;
            this.tabs = res.data;
          }
        })
        .catch(() => {
          this.tabLoading = false;
        });
    },
    // 导出
    handleExport() {
      this.exportLoading = true;
      exportReq({
        ...this.form,
      })
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const time = new Date().getTime();
          const a = document.createElement("a");
          a.setAttribute("download", `进销存列表${time}.xlsx`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    handleColumns() {
      this.tableConf();
    },
    handleReset() {
      // 获取上一个月
      // this.getMonth();
      this.form = {
        hierarchy_id: this.hierarchy_id,
        create_time: this.create_time,
        goods_number: '',
        goods_style_number: '',
      };
      this.getGoodsData();
    },
    handleClickTabs() {
      const [currTab] = this.tabs.filter((item) => {
        return item.field_value === this.form.list_type;
      })
      this.$set(this.columns, 1, {
          field_text: currTab.field_text,
          field_alias: "list_name",
          t_widht: "120px",
          show: true,
          children: [],
          fixed: 'left',
        })
      this.getGoodsData();
    },
    handleSearch() {
      this.getGoodsData();
    },
    handleSuccess() {
      this.tableConf();
    },
    handleIndex({ row, rowIndex }) {
      if (!row.index) {
        row.index = rowIndex + 1;
      }
    },
    // 获取对账数据
    getGoodsData() {
      this.loading = true;
      getGoodsDataReq({
        ...this.form,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // 合计
            const sum = { index: '合计', };
            this.columns.forEach((column) => {
                column.children.forEach((data) => {
                  res.data.forEach((item) => {
                  if (!sum[data.field_alias]) {
                    sum[data.field_alias] = 0;
                  }
                  if (data.type === 'total_weight' || data.type === 'total_gold_weight') {
                    sum[data.field_alias] = numeral(Number(sum[data.field_alias]) + (Number(item[data.field_alias]) || 0)).format('0.0000');
                  } else if (data.type === 'total_num') {
                    sum[data.field_alias] = numeral(Number(sum[data.field_alias]) + (Number(item[data.field_alias]) || 0)).format('0');
                  } else {
                    sum[data.field_alias] = numeral(Number(sum[data.field_alias]) + (Number(item[data.field_alias]) || 0)).format('0.00');
                  }
                })
              })
            })
            res.data.push(sum);
            this.tableData = res.data;
            this.$nextTick(() => {
              this.$refs.dataTable.doLayout();
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.tableData = [];
        });
    },
    // 获取上一个月的天数
    getMonth() {
      const myDate = new Date();
      const tYear = myDate.getFullYear();
      const tMonth = myDate.getMonth() + 1;

      const form = { ...this.form };
      // 如果tMonth不是1月份；
      if (tMonth !== 1) {
        const days = new Date(tYear, tMonth - 1, 0).getDate();
        if (tMonth > 10) {
          form.create_time[0] = `${tYear}-${tMonth - 1}-01`;
          form.create_time[1] = `${tYear}-${tMonth - 1}-${days}`;
          return;
        }
        form.create_time[0] = `${tYear}-0${tMonth - 1}-01`;
        form.create_time[1] = `${tYear}-0${tMonth - 1}-${days}`;
      } else {
        const days = new Date(tYear - 1, 12, 0).getDate();
        form.create_time[0] = `${tYear - 1}-12-01`;
        form.create_time[1] = `${tYear - 1}-12-${days}`;
      }
      this.create_time = form.create_time;
      this.form = form;
    },
    // 获取列表配置
    tableConf() {
      tableConfReq('standing_book')
        .then((res) => {
          if (res.code === 1) {
            this.listConf = res.data;
            this.listConf.forEach((list) => {
              // 未使用需要隐藏列
              this.columns.forEach((item) => {
                item.children.forEach((data) => {
                  if (!list.t_is_use) {
                    if (list.field_alias === data.type) {
                      data.show = false;
                    }
                  } else {
                    if (list.field_alias === data.type) {
                      data.show = true;
                    }
                  }
                });
              });
            });
            this.getGoodsData();
          }
        });
    },
    // 获取门店列表
    getMerchantList() {
      getMerchantListReq().then((res) => {
        if (res.code === 1) {
          this.merchantList = res.data;
          if (res.data.length) {
            const form = {
              ...this.form,
              hierarchy_id: res.data[0].hierarchy_id,
            };
            this.form = form;
            this.hierarchy_id = res.data[0].hierarchy_id;
          }
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleConf() {
      this.title = "列表配置";
      this.dialogVisible = true;
    },
    handleCond(cond) {
      this.page = 1;
      this.cond = { ...cond };
      this.getGoodsData();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  flex: 1;
}
/deep/ .el-form-item {
  margin-bottom: 0px !important;
}
.box {
  // padding: 0 20px;
  padding: 0 20px;
  background: white;
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  // padding-left: 50px;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
}
/deep/ .el-tabs__item {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}
button {
  height: 40px;
}
.left {
  display: flex;
}
.right {
  .line {
    margin: 0 20px;
    width: 1px;
    height: 40px;
    background: #e9e9e9;
  }
}
</style>
